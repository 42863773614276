import AsyncApiStandalone from "@asyncapi/react-component/browser/standalone/index.js";
import "@asyncapi/react-component/styles/default.min.css";

const rootElement = document.getElementById("root");
AsyncApiStandalone.render({
  schema: {
    url: '/swagger.yml',
    options: { method: "GET", mode: "cors" },
  },
  config: {
    show: {
      sidebar: true,
      errors: true,
    },
  },
}, rootElement);
